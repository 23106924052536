@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9fc;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select {
  height: 40px !important;
}

.actionRow {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  margin-bottom: 10px;
}

/* Media query for mobile */
@media only screen and (max-width: 768px) {
  .actionRow {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
  }

  .categoryBox {
    width: 100%;
    margin-bottom: 20px;
  }

  .home-page-image-container {
    height: 150px;
  }
}

.tableWrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 20px;
}

.ag-header-cell-label {
  justify-content: center;
  font-weight: 700;
}

.ag-row-hover {
  background-color: #ecf0f1;
  cursor: pointer !important;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.categoryBox {
  width: 340px;
  height: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 12px;
  color: #111;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 0 30px;
  text-align: center;
  background-color: white;
  border: none;
  padding: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.categoryBox::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #2c9b42;
}

.categoryBox:hover {
  transform: translateY(-5px);
  background-color: white;
  cursor: pointer;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
}

.home-page-image-container {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  background-color: white;
  padding: 0 0 10px 0;
  border-radius: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.categoryDetails {
  margin-top: 3px;
  color: #666666;
  font-size: 14px;
}

.form-input {
  margin-bottom: 10px;
}

.opponentScore {
  margin-bottom: 0px;
}

.ub-mb_8px {
  margin-bottom: 0px;
}

.opponent {
  width: 40%;
  /* border: 1px dotted green; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 0.75rem;
}

.opponentPlayers {
  display: flex;
  flex-direction: row;
  width: 20%;
}

.opponentPlayersWinner {
  display: flex;
  flex-direction: row;
  width: 20%;
  color: #189c41;
  font-weight: 600;
  font-size: 14px;
}

.opponentScoreWinner {
  border: 1px solid #189c41;
  background-color: #189c411a !important;
}

/* Filter Cloud styles - matching schedule page */
ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 20px;
}

ul.cloud li {
  margin: 0 8px;
}

.cloud-button1 {
  border: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;
}

.cloud-button1-active {
  border: none !important;
  font-weight: 600 !important;
  color: #18669c !important;
  background: #18669c26 !important;
  padding: 8px 16px !important;
}

/* Remove the old filter styles */
.filter-container {
  display: none;
}

.filter-container [role='tablist'],
.filter-container [role='tab'],
.filter-container [role='tab'][aria-selected='true'] {
  display: none;
}
