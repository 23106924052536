.ribbon-container {
  height: 57px;
  background-color: #18669c;
  color: white;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 25;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}

.ribbon-back-button {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 10;
}

.ribbon-back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.ribbon-text {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0;
  padding: 0;
}

.ribbon-link-container {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  background: white;
}

.ribbon-button {
  background-color: #18669c;
  color: white;
  font-weight: 600;
}
