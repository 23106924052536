.semis-title {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 30px;
}

.semis-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  text-transform: uppercase;
}

.semisContainer {
  width: 90%;
}

.semis-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
}
