.podium {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  margin: 20px auto;
}

.step {
  width: 80px;
  height: 10px;
  background-color: #ccc;
  border-radius: 5px;
  position: relative;
}

.first {
  height: 100px;
  background-color: gold;
  z-index: 2;
}

.second {
  height: 80px;
  background-color: silver;
  z-index: 1;
  margin-left: -20px;
}

.third {
  height: 60px;
  background-color: #cd7f32;
  margin-right: -20px;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid black;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.winners-container {
  background-color: white;
}

.winners-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
}

/* Media query for large screens */
@media only screen and (min-width: 768px) {
  .winners-list {
    flex-direction: row;
  }
}

.winner-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 150px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.winner-card:hover {
  transform: translateY(-10px);
}

.position {
}

.position-1 {
  color: gold;
  font-size: 6rem;
}

.position-2 {
  color: silver;
  font-size: 5rem;
}

.position-3 {
  color: #cd7f32; /* Bronze color */
  font-size: 4rem;
}

.name {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}
