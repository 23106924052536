.categoryName {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  color: #2c2c2c;
  margin-bottom: 10px;
  padding-top: 5px;
  position: relative;
}

.categoryDetails {
  color: #666666;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
}

.categoryDetails span {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.categoryDetails span:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #2c9b42;
  border-radius: 50%;
  margin-right: 6px;
}
