.categoryContainer {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.groupsContainer {
  width: 100vw;
  /* height: calc(100vh - 100px); */
  background-color: #18669c26;
  /* padding-left: 0%; */
  height: auto;
  padding-bottom: 30px;
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.buttonRow {
  width: 70%;
  display: flex;
  /* border: 1px solid red; */
}

.groupsTitle {
  display: flex;
  width: 98%;
  /* border: 1px solid red; */
  padding-left: 5px;
  align-items: center;
  justify-content: space-between;
}

.categorySummaryContainer {
  /* height: 110px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* padding-left: 10%; */
  margin-bottom: 30px;
}

.groupName {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  text-transform: uppercase;
}

.groupMatchesTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #18669c;
  text-transform: capitalize;
  text-decoration: none;
}

.ag-theme-quartz {
  width: 90%;
  height: fit-content;
  /* margin-top: 30px; */
  --ag-borders: none;
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  margin-top: 25px;
  margin-bottom: 30px;
  margin-left: 5%;
}

/* Media query for large screens */
@media only screen and (min-width: 768px) {
  .ag-theme-quartz {
    width: 60%;
    margin-left: 20%;
  }
}

.ag-header-cell-text {
  color: #a2a2a2;
  font-size: 10px;
}

.ag-cell {
  padding: 0px !important;
  font-size: 12px;
}

.ag-header-cell-resize {
  right: 0px !important;
}

.ag-header-cell-resize {
  display: none;
}

.categorySummaryRoundSummary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.category-name {
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 16px;
  color: #18669c;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
}

.groupInformationContainer {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
}

.groupInfo {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #666666;
  margin-left: 5px;
}

.category-tabs-container {
  padding-bottom: 20px;
}

.opponentScoreWinner {
  border: 1px solid #189c41;
  background-color: #189c411a !important;
}

.category-page-container {
  background-color: #18669c26;
  min-height: 100vh;
}

.category-tabsList {
  background-color: white;
}

.category-tabs-selected {
  font-size: 1rem;
}

.category-tabs {
  font-size: 0.8rem;
}
