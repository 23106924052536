.admin-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin-password-input {
  margin-top: 30px;
}

.admin-submit-button {
  margin-top: 30px;
}

.resetScore {
  margin-top: 30px;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.resetScore-Width {
  width: 100px;
  height: 50px;
}
