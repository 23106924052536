.matchesContainer {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
}

.printableContainer {
  width: 95%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.matchContainer {
  width: 100%;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  position: relative;
}

.matchContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.matchTitle {
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eaedf0;
  margin-bottom: 0;
  font-weight: 600;
  color: #2c2c2c;
}

.matchDescription {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .opponent {
    width: 100%;
    text-align: center;
    flex-direction: row;
  }

  .matchDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
  }

  .matches-group-name {
    font-size: 18px;
  }

  .groupTitle {
    padding: 15px;
  }
}

@media print {
  .submitButton {
    display: none;
  }
}

.printThis {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media print {
  .printThis {
    height: 100% !important;
  }
}

.groupTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  width: 95%;
  max-width: 1200px;
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.matches-group-name {
  color: #5b9d8a;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.25px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
}

.matches-group-name::before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 5px;
  width: 5px;
  background-color: #5b9d8a;
  border-radius: 3px;
}

.matches-group-text {
  font-size: 15px;
  margin-bottom: 8px;
  color: #555;
  font-family: 'Poppins', sans-serif;
}

.matches-group-text b {
  color: #333;
  margin-right: 5px;
}

/* Add a score badge */
.score-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f7ff;
  color: #1e6bb8;
  font-weight: 600;
  font-size: 18px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  margin: 0 10px;
  padding: 0 10px;
  border: 1px solid #e6f0ff;
}

.score-badge.winner {
  background-color: #f2f7f5;
  color: #5b9d8a;
  border: 1px solid #e0ede8;
}

/* Print styles */
@media print {
  .submitButton {
    display: none;
  }

  .printThis {
    height: 100% !important;
  }

  .groupTitle {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 0px;
    box-shadow: none;
    padding: 10px;
  }

  .matchContainer {
    box-shadow: none;
    border: 1px solid #eee;
    page-break-inside: avoid;
  }
}

.printClass {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
