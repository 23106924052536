.tournament-schedule {
  font-family: Arial, sans-serif;
  width: 100vw;
  margin: 0 auto;
}

.event-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.event {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  /* background-color: #29487d; Adjusted background color */
  background-color: #4060be; /* Adjusted background color */
  color: #fff;
}

.event h3 {
  margin-top: 0;
  font-size: 1.2em; /* Decreased font size */
}

.event p {
  margin: 5px 0;
  font-size: 0.9em; /* Decreased font size */
}

.event strong {
  font-weight: bold;
}

.tournament-schedule-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.tournament-schedule-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.tournament-schedule-category {
  min-width: 300px;
  /* box-shadow: 0 10px 30px; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  height: fit-content;
  padding-bottom: 10px;
}

.tournament-schedule-category-name {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #18669c;
  background-color: #18669c26;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tournament-schedule-category-date {
  color: #666666;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 20px;
  background-color: #18669c26;
}

.tournament-schedule-category-players {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 25px;
}

.tournament-schedule-category-player {
  width: fit-content;
  margin-bottom: 15px;
  text-align: left;
  color: #666666;
}
.tournament-schedule-category-player-highlight {
  width: fit-content;
  margin-bottom: 15px;
  text-align: left;
  color: red;
}

.tournament-schedule-reminders {
  width: 80%;
  text-align: center;
  height: 60px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
  /* border-bottom: 1px solid #666666; */
  /* margin-bottom: 10px; */
  /* background-color: #18669c26; */
  margin-left: 10%;
}

ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 80%;
  margin-left: 10%;
}

.cloud-button1 {
  border: none !important;
  font-weight: 500 !important;
}

.cloud-button1-active {
  border: none !important;
  font-weight: 600 !important;
  color: #18669c !important;
  background: #18669c26 !important;
}
/* Media query for mobile */
@media only screen and (min-width: 768px) {
  .tournament-schedule-category {
    width: 30%;
  }
}
