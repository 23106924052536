.newCategoryContainer {
  display: flex;
  justify-content: center;
}

.card {
  width: 30%;
  padding: 20px;
  margin: 20px;
}

.groupPlayerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

label {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .newCategoryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card {
    width: 80%;
    padding: 20px;
    margin: 20px;
  }
}
